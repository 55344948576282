const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://upstreet.digital' : 'http://192.168.100.106:3060',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://upstreet.digital' : 'http://192.168.100.106:3060',
    api: 'api/',
    apiSocket: 'upstreet::1.2',
    nameDir: 'upstreet',
    package: 'digital.upstreet.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyB0rF3a2NaS52fG5ItCZZ-pK5viRka5kY8',
    appName: 'UpStreet',
    provider: '',
    colorPrimary: '#7F00B3',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'digital.upstreet.passenger',
    playStoreDriverId: 'digital.upstreet.driver',
    appStorePassengerId: '6739266234',
    appStoreDriverId: '6739266014',
    email: "yuri_t_n@hotmail.com",
};
export default config;
